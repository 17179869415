import $ from "jquery";
export default class Career {
    constructor() {
        let career = document.getElementsByClassName('career').item(0);

        $('.career .c-item').hover((e) => {
            let items = document.getElementsByClassName('c-item');
            let itemsArray = Array.from(items);
            let current = e.currentTarget;

            let careerHeight = Math.floor(current.offsetTop + (current.offsetHeight / 2)) + 'px';

            career.style.setProperty('--career-height', careerHeight);


            let changeItems = itemsArray.splice(0, itemsArray.indexOf(current) + 1);

            changeItems.map((item) => {
                item.classList.add('active');
            });
        }, (e) => {
            career.style.setProperty('--career-height', '0px');
            $('.c-item').removeClass('active');
        });
    }
}

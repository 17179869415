import 'vanilla-cookieconsent';


export default class CookieBar {
    constructor() {
        // obtain plugin
        this.cc = initCookieConsent();
        this.settings = cookiesettings;
        this.blocks = this.settings.blocks.map((item, key) => {

            let ret = {
                title: item.title,
                description: item.description,
            };

            if (item.toggle_value) {
                ret['toggle'] = {
                    value: item.toggle_value,
                    enabled: item.toggle_enabled,
                    readonly: item.toggle_readonly
                };
            }

            return ret;
        })

        // run plugin with your configuration
        this.cc.run({
            current_lang: 'de',
            autoclear_cookies: true,                   // default: false
            page_scripts: true,                        // default: false
            cookie_expiration: 1,                 // default: 182 (days)
            gui_options: {
                consent_modal: {
                    layout: 'cloud',               // box/cloud/bar
                    position: 'bottom center',     // bottom/middle/top + left/right/center
                    transition: 'slide',           // zoom/slide
                    swap_buttons: false            // enable to invert buttons
                },
                settings_modal: {
                    layout: 'bar',                 // box/bar
                    position: 'right',           // left/right
                    transition: 'slide'            // zoom/slide
                }
            },
            languages: {
                'de': {
                    consent_modal: {
                        title: this.settings.title,
                        description: this.settings.description + '<br/><button type="button" data-cc="c-settings" class="cc-link">'+this.settings.settings_title+'</button>',
                        primary_btn: {
                            text: this.settings.accept_all_label,
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: this.settings.deny_all_label,
                            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: this.settings.settings_title,
                        save_settings_btn: this.settings.save_label,
                        accept_all_btn: this.settings.accept_all_label,
                        reject_all_btn: this.settings.deny_all_label,
                        close_btn_label: this.settings.close_label,
                        cookie_table_headers: [
                            {col1: 'Name'},
                            {col2: 'Domain'},
                            {col3: 'Ablaufdatum'},
                            {col4: 'Beschreibung'}
                        ],
                        blocks: this.blocks
                    }
                }
            }
        });
    }
}

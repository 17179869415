import Swiper, {Navigation, Pagination} from 'swiper';

export default class ContentSlider {
    constructor() {
        this.swiper = new Swiper('.default-swiper', {
            slidesPerView: 1,
            spaceBetween: 30,
            modules: [Navigation],
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                900: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            }
        });

        this.feedback = new Swiper('.feedback-swiper', {
            slidesPerView: 1,
            modules: [Navigation, Pagination],
            autoHeight: true,
            pagination: {
                el: ".custom-pagination",
                clickable: true,
            },
        });
    }
}

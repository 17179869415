export default class ResponsiveImages {
    constructor() {
        let images = document.querySelectorAll('img[srcset].responsive-image');
        [...images].map(img => {
            img.sizes = img.offsetWidth + 'px';
            if(!img.complete) img.classList.add('blur');
            img.addEventListener('load', e => {
                e.target.classList.remove('blur');
                e.target.classList.add('loaded');
            });
        });

        window.onresize = () => {
            [...images].map(img => {
                img.sizes = img.offsetWidth + 'px';
            });
        }
    }
}
class MobileNavigation {
    constructor() {
        this.buttons = [...document.querySelectorAll('[data-toggle-mobile-navigation]')];
        this.buttons.map((item, key) => {
            item.addEventListener('click', () => this.toggle());
        })

        this.mobileNavigation = document.querySelector('.mobile-navigation');
        this.mobileNavigation.addEventListener('click', (e) => {
            if (e.target === this.mobileNavigation) {
                this.toggle();
            }
        })

    }

    toggle() {
        this.mobileNavigation.classList.toggle('active');
    }
}

export default MobileNavigation;

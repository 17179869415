import $ from "jquery";

export default class Form {
    constructor() {
        let removeEmptyValue = (el) => {
            el.classList.remove('filled');
            el.value = '';
        }

        $('.form input, .form textarea').each((i, e) => {
            console.log()
            let current = e;
            let value = current.value;
            value.trim().length > 0 ? current.classList.add('filled') : removeEmptyValue(current);
        });

        $('.form input, .form textarea').blur((e) => {
            let current = e.currentTarget;
            let value = current.value;
            value.trim().length > 0 ? current.classList.add('filled') : removeEmptyValue(current);
        });
    }
}

import { Fancybox } from "@fancyapps/ui";

import MobileNavigation from "./components/MobileNavigation";
import ResponsiveImages from "./components/ResponsiveImages";
import CookieBar from "./components/CookieBar";
import InView from "./components/InView";
import ContentSlider from "./components/ContentSlider";
import Career from "./components/Career";
import Form from "./components/Form";

document.addEventListener("DOMContentLoaded", (event) => {
    new MobileNavigation();
    new ResponsiveImages();
    new CookieBar();
    new InView();
    new ContentSlider();
    new Career();
    new Form();
});
